import { FaGithub, FaInstagram, FaLinkedin, FaSnapchat, FaYoutube } from "react-icons/fa";
import { useRef, useState } from "react";
import { motion } from "framer-motion";
import { SiFiverr } from "react-icons/si";
import { TbBrandFiverr } from "react-icons/tb";

const Footer = () => {
  // Create separate states for each icon's position
  const [linkedinPosition, setLinkedinPosition] = useState({ x: 0, y: 0 });
  const [youtubePosition, setYoutubePosition] = useState({ x: 0, y: 0 });
  const [snapchatPosition, setSnapchatPosition] = useState({ x: 0, y: 0 });
  const [instagramPosition, setInstagramPosition] = useState({ x: 0, y: 0 });

  const handleMouse = (e, setPosition) => {
    const { clientX, clientY } = e;
    const ref = e.currentTarget;
    const { height, width, left, top } = ref.getBoundingClientRect();
    const middleX = clientX - (left + width / 2);
    const middleY = clientY - (top + height / 2);
    setPosition({ x: middleX, y: middleY });
  };

  const resetPosition = (setPosition) => {
    setPosition({ x: 0, y: 0 });
  };

  // Animation properties for scaling and rotation
  const animationProps = {
    scale: 1.2,  // Scale up to 120%
    rotate: 10,  // Rotate by 10 degrees
  };

  return (
    <div className='relative h-[300px] ' style={{ clipPath: "polygon(0% 0, 100% 0%, 100% 100%, 0 100%)" }}>
      <footer className="bg_light_blue text-white py-12 text-center fixed bottom-0 h-[300px] w-full px-2">
        <div className="flex justify-center items-center mb-6">
          <span className="text-3xl sm:text-4xl font-bold">
            Muhammad <span className="text-[#F6BA08]">Bilal</span>
          </span>
        </div>

        <div className="flex justify-center space-x-8 mb-4">
          <a href="https://www.linkedin.com/in/muhammadbilal900/" target="_blank" rel="noopener noreferrer">
            <motion.div
              style={{ position: "relative" }}
              onMouseMove={(e) => handleMouse(e, setLinkedinPosition)}
              onMouseLeave={() => resetPosition(setLinkedinPosition)}
              animate={{ ...linkedinPosition, scale: 1, rotate: 0 }} // Reset scale and rotation
              whileHover={{ scale: animationProps.scale, rotate: animationProps.rotate }} // Scale and rotate on hover
              transition={{
                type: "spring",
                stiffness: 150,
                damping: 15,
                mass: 0.1,
              }}
            >
              <FaLinkedin className="text-white text-4xl sm:text-5xl" />
            </motion.div>
          </a>

          <a href="https://www.fiverr.com/s/o8eDj0L" target="_blank" rel="noopener noreferrer">
            <motion.div
              style={{ position: "relative" }}
              onMouseMove={(e) => handleMouse(e, setYoutubePosition)}
              onMouseLeave={() => resetPosition(setYoutubePosition)}
              animate={{ ...youtubePosition, scale: 1, rotate: 0 }} // Reset scale and rotation
              whileHover={{ scale: animationProps.scale, rotate: animationProps.rotate }} // Scale and rotate on hover
              transition={{
                type: "spring",
                stiffness: 150,
                damping: 15,
                mass: 0.1,
              }}
            >
              <TbBrandFiverr className="text-white text-4xl sm:text-5xl" />
            </motion.div>
          </a>

          <a href="https://github.com/BilalDeveloper900" target="_blank" rel="noopener noreferrer">
            <motion.div
              style={{ position: "relative" }}
              onMouseMove={(e) => handleMouse(e, setSnapchatPosition)}
              onMouseLeave={() => resetPosition(setSnapchatPosition)}
              animate={{ ...snapchatPosition, scale: 1, rotate: 0 }} // Reset scale and rotation
              whileHover={{ scale: animationProps.scale, rotate: animationProps.rotate }} // Scale and rotate on hover
              transition={{
                type: "spring",
                stiffness: 150,
                damping: 15,
                mass: 0.1,
              }}
            >
              <FaGithub className="text-white text-4xl sm:text-5xl" />
            </motion.div>
          </a>

          <a href="https://www.instagram.com/bilal__developer/" target="_blank" rel="noopener noreferrer">
            <motion.div
              style={{ position: "relative" }}
              onMouseMove={(e) => handleMouse(e, setInstagramPosition)}
              onMouseLeave={() => resetPosition(setInstagramPosition)}
              animate={{ ...instagramPosition, scale: 1, rotate: 0 }} // Reset scale and rotation
              whileHover={{ scale: animationProps.scale, rotate: animationProps.rotate }} // Scale and rotate on hover
              transition={{
                type: "spring",
                stiffness: 150,
                damping: 15,
                mass: 0.1,
              }}
            >
              <FaInstagram className="text-white text-4xl sm:text-5xl" />
            </motion.div>
          </a>
        </div>
        
        <div className="mb-2">
          <a href="mailto:hello@leadsurger.com">bilaldeveloper900@gmail.com</a>
        </div>

        <div className="mb-2">
          © 2024 Muhammad Bilal. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default Footer;
